import React from 'react';
import isMobile from 'is-mobile';
import CookieConsent from "react-cookie-consent";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAt, faDownload, faEnvelope, faMapLocationDot, faPause, faPhone, faPlay, faRefresh, faShare } from '@fortawesome/free-solid-svg-icons';
import { Button, Col, Dropdown, Offcanvas, Row } from 'react-bootstrap';
import { faFacebook, faInstagram, faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import Marquee from 'react-fast-marquee';
import moment from 'moment';

import bkLogo from '../../assets/img/logo_white.png';
import logo from '../../assets/img/logo.png';
import './index.css';
import { loadFirebaseMessaging, logAnalyticsEvent } from '../../services/firebase';

export interface IAppProps {
}

export interface IAppState {
  onLoaded: boolean;
  pulseWhatsAppButton: boolean;
  showInstallPwa: boolean;
  playMarquee: boolean;
  showOffCanvaMessage: boolean;
}

export default class App extends React.Component<IAppProps, IAppState> {

  offCanvaMessageId = "";
  offCanvaMessage = <>Prezado cliente Speed's, manter a <b>qualidade dos nossos insumos</b> sempre foi e será <b>nossa prioridade!</b><br/>
  Devido situação atual da nossa economia, dia <b>25/08/2022</b> estaremos realizando um reajuste de 12% em nossos produtos.<br/>
  Quaisquer dúvidas ou sugestões entre em contato conosco.<br/></>;

  marqueeText = "🎉🎉 Bem-Vindo a Speed's Pizzeria 🍕🍕! Qualquer dúvida basta entrar em contato conosco! 👉 👉 👉 👉 ";
  gPageLink = "https://g.page/speedspizzeria?share";
  //olaCLickLink = "https://speeds-pizzeria.ola.click/products";
  olaCLickLink = "https://menu-app.speedspizzeria.com.br/products";
  whatsAppLink = "https://wa.me/5571996413840";
  phoneCallLink = "tel://+5571996413840";
  emailLink = "mailto:atendimento@speedspizzeria.com.br";
  facebookLink = "https://www.facebook.com/speedspizzeria";
  instagramLink = "https://www.instagram.com/speedspizzeria";
  forceReloadUrl = "?force-reload=true";
  accessCount = 0;
  deferredPromptPwaInstall: any;
  ensureIFrameLoaded = false;

  constructor(props: IAppProps) {
    super(props);

    this.accessCount = this.accessAppInc();
    this.state = {
      onLoaded: false,
      pulseWhatsAppButton: false,
      showInstallPwa: false,
      playMarquee: true,
      showOffCanvaMessage: this.showOffCanvaMessage(),
    };

    window.addEventListener('beforeinstallprompt', (e) => {
      this.deferredPromptPwaInstall = e;
      this.setState({showInstallPwa: true});
    });
  }

  componentDidMount() {
    if (!localStorage.getItem('view-whatsapp-menu')) {
      setTimeout(() => this.setState({pulseWhatsAppButton: true}), 4000);
    }
    if (!isMobile() || this.runingAsPWA()) {
      loadFirebaseMessaging();
    }
    if (this.runingAsPWA()) {
      logAnalyticsEvent('loaded_as_pwa', {});
    }
  }

  openLink(link: string) {
    window.open(link);
    logAnalyticsEvent('click', {ui_item: 'openLink', link});
  }

  share() {
    navigator.share({
      title: `Speed's Pizzeria`,
      text: `Speed's Pizzeria em Massarandupió! A melhor Pizza sem borda, crocante e com molho pomodoro natural`,
      url: `${location.protocol}//${location.host}`,
    });
    logAnalyticsEvent('click', {ui_item: 'share'});
  }

  accessWhatsAppButton() {
    localStorage.setItem('view-whatsapp-menu', moment().format());
    this.setState({pulseWhatsAppButton: false})
    logAnalyticsEvent('click', {ui_item: 'accessWhatsAppButton'});
  }

  accessOffCanvaMessage() {
    localStorage.setItem('view-message-' + this.offCanvaMessageId, moment().format());
    this.setState({showOffCanvaMessage: false})
    logAnalyticsEvent('click', {ui_item: 'accessOffCanvaMessage', message_id: this.offCanvaMessageId});
  }

  showOffCanvaMessage() {
    if (this.offCanvaMessageId === '') {
      return false;
    }
    var lastMsgView = localStorage.getItem('view-message-' + this.offCanvaMessageId);
    if (lastMsgView) {
      const dateDiff = moment(lastMsgView).diff(moment(), 'days');
      return (dateDiff > 4);
    }
    return (this.accessCount > 1);
  }

  accessAppInc() {
    const count = parseInt(localStorage.getItem('access-count') || '0') + 1;
    localStorage.setItem('access-count', count.toString());
    return count;
  }

  async installPwa() {
    this.setState({showInstallPwa: false});
    this.deferredPromptPwaInstall.prompt();   
  }

  runingAsPWA() {
    const isStandalone = window.matchMedia('(display-mode: standalone)').matches;
    if (document.referrer.startsWith('android-app://')) {
      return true;
    } else if ((navigator as any).standalone || isStandalone) {
      return true;
    }
    return false;
  }

  onLoaded() {
    this.setState({onLoaded: true});
  }

  // @ts-ignore
  isSafari = () => /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && safari.pushNotification));
  
  public render() {

    const {onLoaded,pulseWhatsAppButton, playMarquee, showOffCanvaMessage, showInstallPwa} = this.state;
    const runingAsPWA = this.runingAsPWA();
    const baseClasses = `${isMobile() ? 'mobile' : ''} ${runingAsPWA ? 'pwa' : ''} ${this.isSafari() ? 'safari' : ''}`;

    return (<>

      <header>
        <div className='header-container'>
          <img id="header-logo" src={bkLogo} />                   
          {(!showOffCanvaMessage && !showInstallPwa && (this.marqueeText != '')) && <>
            <Button size="sm" variant='link' style={{marginLeft: -10, color: '#fff'}} onClick={() => this.setState({playMarquee: !playMarquee})}>
              <FontAwesomeIcon icon={playMarquee? faPause : faPlay} />
            </Button> 
            <Marquee delay={2} style={{maxWidth: 'calc(100vw - 246px)', width: 'calc(100% - 221px)', marginRight: 6}} gradient={false} play={playMarquee}>{this.marqueeText}</Marquee>
          </>}   

          {showInstallPwa && 
          <Button onClick={() => this.installPwa()} variant="danger" size="sm" style={{marginRight: 12}}>
            <FontAwesomeIcon icon={faDownload} /> &nbsp; Instalar App
          </Button>}       

          <Dropdown style={{display: 'inline-block'}} onClick={() => this.accessWhatsAppButton()}>
            <Dropdown.Toggle variant="success" id="dropdown-basic" className={pulseWhatsAppButton? 'pulse' : ''} size="sm">
              <FontAwesomeIcon icon={faWhatsapp} /> 71 99641-3840
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item onClick={() => this.openLink(this.whatsAppLink)}><FontAwesomeIcon icon={faWhatsapp} /> &nbsp;Mensagem</Dropdown.Item>
              <Dropdown.Item onClick={() => this.openLink(this.phoneCallLink)}><FontAwesomeIcon icon={faPhone} /> &nbsp;Ligação</Dropdown.Item>
              <Dropdown.Item onClick={() => this.openLink(this.gPageLink)}><FontAwesomeIcon icon={faMapLocationDot} /> &nbsp;Localização</Dropdown.Item>
              <Dropdown.Item onClick={() => this.openLink(this.emailLink)}><FontAwesomeIcon icon={faAt} /> &nbsp;E-mail</Dropdown.Item>
              <Dropdown.Item onClick={() => this.openLink(this.facebookLink)}><FontAwesomeIcon icon={faFacebook} /> &nbsp;@speedspizzeria</Dropdown.Item>
              <Dropdown.Item onClick={() => this.openLink(this.instagramLink)}><FontAwesomeIcon icon={faInstagram} /> &nbsp;@speedspizzeria</Dropdown.Item>              
              <Dropdown.Divider />
              {(!!window.navigator.share) && <Dropdown.Item onClick={() => this.share()}><FontAwesomeIcon icon={faShare} /> &nbsp;Compartilhar</Dropdown.Item>}
              <Dropdown.Item onClick={() => window.location.href = this.forceReloadUrl}><FontAwesomeIcon icon={faRefresh} /> &nbsp;Atualizar</Dropdown.Item>              
            </Dropdown.Menu>
          </Dropdown>
        </div>        
      </header>

      <div id="main-container" className={`${baseClasses}`}>
        {/* {!onLoaded && <img id="bk-main-logo" src={bkLogo} />} */}
        <iframe id='menu-iframe' className={`body-iframe ${baseClasses}`} src={this.olaCLickLink} onLoad={() => this.onLoaded()} />
        <footer className={`${baseClasses}`}></footer>        
        <CookieConsent buttonText="Aceitar Todos os Cookies" buttonStyle={{ color: "#fff", backgroundColor: '#f51414' }}>
          A Speed's Pizzeria utiliza cookies que permitem funcionalidades essenciais do site e melhoram a sua experiência na plataforma. <a href="https://www.gov.br/cidadania/pt-br/acesso-a-informacao/lgpd" target="_blank">Saber mais sobre a LGDP.</a>
        </CookieConsent>
      </div>

      {showOffCanvaMessage && <Offcanvas backdrop="static" placement="bottom" show={showOffCanvaMessage} onHide={() => this.accessOffCanvaMessage()}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title><FontAwesomeIcon icon={faEnvelope}/> &nbsp;Mensagem Speed's</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Row>
            <Col md="2" style={{textAlign: 'center'}}>
              <img src={logo} width={100}/>
            </Col>
            <Col>{this.offCanvaMessage}</Col>
          </Row>
          <Row>
            <Button onClick={() => this.accessOffCanvaMessage()} variant="danger">Fechar</Button>
          </Row>
        </Offcanvas.Body>        
      </Offcanvas>}
    </>);
  }
}