import { FirebaseApp, initializeApp } from "firebase/app";
import { Analytics, getAnalytics, logEvent } from "firebase/analytics";
import { getMessaging, onMessage, getToken } from "firebase/messaging";

let fbApp: FirebaseApp | null;
let fbAnalitics: Analytics | null;

export async function loadFirebase() {
  const firebaseConfig = {
    apiKey: "AIzaSyCGJNy71Z9T5aPcF18Ne2K77ulX14jNf0A",
    authDomain: "speeds-pizzeria.firebaseapp.com",
    databaseURL: "https://speeds-pizzeria.firebaseio.com",
    projectId: "speeds-pizzeria",
    storageBucket: "speeds-pizzeria.appspot.com",
    messagingSenderId: "263926933733",
    appId: "1:263926933733:web:448dd3dc5858ae24aecc0c",
    measurementId: "G-WJZFR4VXQG"
  };

  fbApp = initializeApp(firebaseConfig);
  fbAnalitics = getAnalytics(fbApp);
  fbAnalitics.app.automaticDataCollectionEnabled = true;
}
export function logAnalyticsEvent(event: string, params: any) {
  if (!fbAnalitics) {
    console.log(`logAnalyticsEvent, fbAnalitics not initialized`);
    return;
  }
  logEvent(fbAnalitics, event, params);
}

export async function loadFirebaseMessaging() {
  console.log(`loadFirebaseMessaging`);
  if (!fbApp) {
    //throw new Error(`fbApp not initialized`);
    console.log(`loadFirebaseMessaging, fbApp not initialized`);
    return;
  }

  const messaging = getMessaging(fbApp);
  const vapidKey = 'BEvo_vtQFzmiBrs6pazSB9U8JqNjn0rNiTWCSDZ79w7zzAUsYJxbtgHRge11LK-M6IrF-JhPepFK-ZUi6vWf5I0';
  getToken(messaging, {vapidKey}).then((currentToken) => {
    console.log('Firebase token', currentToken);
    if (Notification.permission !== 'granted') {
      Notification.requestPermission().then((permission) => {
        if (!currentToken && (permission === 'granted')) {
          getToken(messaging, {vapidKey});
        }
      });
    }
  }).catch((err) => {
    console.log('An error occurred while retrieving token. ', err);
  });

  onMessage(messaging, (payload: any) => {
    console.log('Firebase Message received. ', payload);
    const notificationTitle = payload.notification.title;
    const notificationOptions = {
      body: payload.notification.body,
      icon: payload.notification.image || 'https://www.speedspizzeria.com.br/logo192.png'
    };
    navigator.serviceWorker.getRegistration('/firebase-cloud-messaging-push-scope').then((registration: any) => {
        registration.showNotification(notificationTitle,notificationOptions);
    });
  });
}
