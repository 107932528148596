import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './containers/app';

import "@fontsource/roboto";
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import { loadFirebase, logAnalyticsEvent } from './services/firebase';

const urlArgs = new URL(window.location.href).searchParams;

const startApp = () => {
  const root = ReactDOM.createRoot(document.body as HTMLElement);
  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
  );
}

loadFirebase();
logAnalyticsEvent('open', {source: urlArgs.get('s') || 'none', s_referrer: document.referrer});
startApp();